<script setup>
import LogoAzimutRgbReverseHorizontal from '~/assets/icons/Logo_Azimut_RGB_Reverse_Horizontal.svg';

const {
    navs,
} = useSharedState();
</script>
<template>
    <footer class="lg:pr-24">
        <div class="flex flex-wrap justify-between px-7 pb-12 pt-9 lg:px-17">
            <div class="w-full lg:w-12/24">
                <LogoAzimutRgbReverseHorizontal class="-mt-9 mb-8 h-auto w-36 max-w-full xs:w-45 lg:mb-0 lg:w-80" />
                <div class="hidden lg:block">
                    <ul
                        class="mt-12 flex gap-4"
                    >
                        <li
                            v-for="(link, index) in navs.socials"
                            :key="link.id"
                            :data-index="index + navs.socials.length"
                            class="mb-1"
                        >
                            <a
                                :href="link.uri"
                                class="inline-block fill-current transition hover:text-carmin"
                                target="_blank"
                                v-html="link.icon.inline"
                            />
                        </li>
                    </ul>
                </div>
            </div>
            <div class="w-full lg:w-12/24">
                <ul
                    class="mb-6 w-full text-4xl lg:mb-4"
                >
                    <li
                        v-for="(item, index) in navs.main"
                        :key="item.id"
                        class="mb-3"
                        :data-index="index"
                    >
                        <MainLink
                            :to="item.uri"
                            class="border-b-[0.135em] border-transparent transition hover:!border-carmin"
                            active-class="!border-carmin"
                        >
                            {{ item.title }}
                        </MainLink>
                    </li>
                </ul>
                <div class="lg:hidden">
                    <ul
                        class="flex gap-4"
                    >
                        <li
                            v-for="(link, index) in navs.socials"
                            :key="link.id"
                            :data-index="index + navs.socials.length"
                            class="mb-1"
                        >
                            <a
                                :href="link.uri"
                                class="inline-block fill-current"
                                target="_blank"
                                v-html="link.icon.inline"
                            />
                        </li>
                    </ul>
                </div>
            </div>
            <div class="lg:flex-no-wrap mt-4 flex w-full flex-wrap justify-between text-xxs text-graphite lg:mt-29">
                <div class="w-full lg:w-12/24">
                    <ul
                        class="mb-16 flex lg:mb-0"
                    >
                        <li
                            v-for="(item, index) in navs.secondary"
                            :key="item.id"
                            :data-index="index + navs.main.length"
                            class="mb-1 ml-3 border-l border-current pl-3 leading-none first:ml-0 first:border-none first:pl-0"
                        >
                            <MainLink
                                class="border-b-[0.135em] border-transparent transition hover:!border-current"
                                active-class="!border-current"
                                :to="item.uri"
                            >
                                {{ item.title }}
                            </MainLink>
                        </li>
                    </ul>
                </div>
                <div class="flex w-full lg:w-1/2">
                    <div>
                        © Azimut Architecture inc.
                    </div>
                    <div class="ml-auto">
                        Site réalisé par <a
                            class="border-b border-transparent font-bold uppercase transition hover:border-[#9FD2BA] hover:text-[#9FD2BA]"
                            href="https://guimauvecreative.com"
                            target="_blank"
                        >Guimauve</a>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>
